import { Alert, Box, Button, Card, Chip, CircularProgress, Divider, Stack, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { BiLink } from 'react-icons/bi';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';
import { IDependent } from 'src/pages/dashboard/@types';
import axiosInstance from 'src/utils/axios';
import { parseISOLocal } from 'src/utils/formatTime';
import DependentAvatar from "../DependentAvatar";
import Iconify from "../Iconify";

const CardStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: '1px solid #919EAB3D',
  width: '380px',
  borderRadius: '16px',
  margin: '10px',
  padding: '16px',
}));

const Title = styled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.grey[800],
  marginTop: '8px',
}));

const Description = styled('span')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.grey[800],
}));

const DescriptionName = styled('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.info.darker,
  textTransform: 'capitalize',
  fontSize: '14px',
  lineHeight: '22px',
}));

interface CardDependentProps {
  dependent: IDependent;
  handleClickOpen: (dependent: IDependent) => void;
}

export function CardDependent({ handleClickOpen, dependent }: CardDependentProps) {
  const { user, selectedContract } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const dependentBirthday = parseISOLocal(dependent.birthdate);
  const dependentBirthdayFormat = new Intl.DateTimeFormat('pt-BR').format(
    new Date(dependentBirthday)
  );

  const dependentUpdated =
    dependent.already_updated && dependent.cpf && dependent.email && dependent.phone_number;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  async function sendEmailUpdateDependent() {
    setLinkGenerated(true);
    try {
      const response = await axiosInstance.post(`/users/dependent-access/${dependent.id}`, {
        email: dependent.email,
      });

      const linkUpdate = response.data;

      shareLink(linkUpdate);

      // copyLink(linkUpdate);
    } catch (err) {
      toast.error(err.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setLinkGenerated(false);
    }
  }

  function shareLink(link: string) {
    if (navigator.share) {
      navigator
        .share({
          title: 'Conceder acesso de dependente',
          text: 'Conceder acesso de dependente',
          url: link,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  function formatParentesco(parentesco: string) {
    switch (parentesco) {
      case 'MAE':
        return 'Mãe';
      case 'PAI':
        return 'Pai';
      case 'FILHO':
        return 'Filho(a)';
      case 'ENTEA':
        return 'Enteado(a)';
      case 'IRMA':
        return 'Irmã(o)';
      case 'AVO':
        return 'Avó(ô)';
      case 'NETO':
        return 'Neto(a)';
      case 'CONJ':
        return 'Cônjuge';
      case 'AFILH':
        return 'Afilhado(a)';
      case 'OUTRO':
        return 'Outro';
      default:
        return parentesco;
    }
  }

  function formatCPF(cpf: string | null) {
    if (!cpf || cpf.length !== 11) return '';
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  function formatPhoneNumber(phoneNumber: string | null) {
    if (!phoneNumber) return '';

    // Remove tudo que não for número
    const numbers = phoneNumber.replace(/\D/g, '');

    // Se tiver 11 dígitos (com 9)
    if (numbers.length === 11) {
      return numbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    // Se tiver 10 dígitos, formata normalmente
    if (numbers.length === 10) {
      return numbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }

    return '';
  }

  return (
    <CardStyle>
      <input type="text" id="link-hidden" style={{ display: 'none' }} />
      <Card
        sx={{
          padding: 1,
          paddingLeft: 2,
          marginTop: 1,
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" minHeight="45px" gap={1}>
          <DependentAvatar userId={Number(dependent.id)} hasImage={(dependent.image != null)} />
          <DescriptionName>{dependent.name.toUpperCase() ?? ''}</DescriptionName>
        </Box>
      </Card>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        variant="fullWidth"
        sx={{ mb: '0.5rem' }}
      >
        <Tab label="Dados Pessoais" />
        <Tab label="Endereço" />
      </Tabs>
      {selectedTab === 0 && (
        <Box>
          <Title>
            Parentesco: <Description>{formatParentesco(dependent.relationship)}</Description>
          </Title>
          <Title>
            Contrato Vinculado: <Description>{dependent.contract_number}</Description>
          </Title>
          <Title>
            Data de nascimento: <Description>{dependentBirthdayFormat ?? '-'}</Description>
          </Title>
          <Title>
            CPF: <Description>{formatCPF(dependent.cpf)}</Description>
          </Title>
          <Title>
            Estado Civil: <Description>{dependent.marital_status ?? '-'}</Description>
          </Title>
          <Title>
            Email: <Description>{dependent.email ?? '-'}</Description>
          </Title>
          <Title>
            Número do Celular: <Description>{formatPhoneNumber(dependent.phone_number)}</Description>
          </Title>
          <Title>
            Telefone Residencial: <Description>{formatPhoneNumber(dependent.home_phone)}</Description>
          </Title>
          <Title>
            Outro Telefone: <Description>{formatPhoneNumber(dependent.other_phone)}</Description>
          </Title>
        </Box>
      )}
      {selectedTab === 1 && (
        <Box>
          <Title>
            CEP: <Description>{dependent.zip_code}</Description>
          </Title>
          <Title>
            Logradouro: <Description>{dependent.adress}</Description>
          </Title>
          <Title>
            Número: <Description>{dependent.house_number}</Description>
          </Title>
          <Title>
            Bairro: <Description>{dependent.neighborhood}</Description>
          </Title>
          <Title>
            Cidade: <Description>{dependent.city}</Description>
          </Title>
          <Title>
            Estado: <Description>{dependent.state}</Description>
          </Title>
          <Title>
            Complemento: <Description>{dependent.complement}</Description>
          </Title>
          <Title>
            Ponto de Referência: <Description>{dependent.reference_point}</Description>
          </Title>
        </Box>
      )}
      {selectedContract?.role !== 'PROPOSAL_USER' && (
        <>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
            {!dependentUpdated && (
              <>
                {/* ------------------ GERAR LINK ------------------ */}
                {linkGenerated ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  <Button
                    variant="contained"
                    sx={{ fontSize: '13px' }}
                    onClick={() => sendEmailUpdateDependent()}
                  >
                    <BiLink size={18} style={{ marginRight: '4px' }} />
                    Gerar link de atualização
                  </Button>
                )}
              </>
            )}
            {/* ------------------ BOTÃO DE EDITAR ------------------ */}
            <Button
              onClick={() => handleClickOpen(dependent)}
              sx={{ display: 'flex', alignItems: 'center' }}
              variant="contained"
              color="secondary"
              fullWidth={Boolean(dependentUpdated)}
            >
              {' '}
              <HiOutlinePencilAlt size={14} />{' '}
              <span style={{ marginLeft: '9.5px' }}>
                {dependentUpdated ? 'Alterar' : 'Atualizar'}
              </span>
            </Button>
          </Box>

          {/* ------------------ LABEL DE ATUALIZAR ------------------ */}
          <Box marginTop={2}>
            {dependentUpdated ? (
              <Alert variant="outlined" severity="success" sx={{ padding: '0 0 0 10px' }}>
                Dependente atualizado
              </Alert>
            ) : (
              <Alert variant="outlined" severity="error" sx={{ padding: '0 0 0 10px' }}>
                Dependente não atualizado
              </Alert>
            )}
          </Box>
          {dependent.password != null && (<Box marginTop={1}>
            <Alert variant="outlined" severity="info" sx={{ padding: '0 0 0 10px' }}
              iconMapping={{
                info: <Iconify icon={'fluent:phone-checkmark-16-regular'} />
              }}>
              Acesso realizado
            </Alert>
          </Box>)}
        </>
      )}
    </CardStyle>
  );
}

