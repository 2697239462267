import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Button } from '@mui/material';
import ReactCodeInput from 'react-code-input';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import { useSnackbar } from 'notistack';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

// ----------------------------------------------------------------------

export default function StepThree() {
  const [pinCode, setPinCode] = useState('');
  const { setStepTwo, setStepThree, setStepFour, typeSend, currentUser } = useUpdateSteps();
  const { enqueueSnackbar } = useSnackbar();
  const [totalTimeSeconds, setTotalTimeSeconds] = useState(2 * 60);

  const minutes = Math.floor(totalTimeSeconds / 60);
  const seconds = totalTimeSeconds % 60;

  async function resendMessage() {
    setTotalTimeSeconds(2 * 60);
    try {
      const response = await axiosInstance.post(
        `/users/confirmation/${currentUser.phone_number}?type=${typeSend}`,
        {
          id: currentUser.id,
        }
      );

      const { code } = response.data;

      if (process.env.REACT_APP_ENABLE_TEST === 'true') {
        enqueueSnackbar(`codigo ${code}`, {
          persist: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function acessStepThree() {
    try {
      await axiosInstance.post(`/users/verify/${pinCode}`, {
        id: currentUser.id,
      });
      setStepFour(true);
      setStepThree(false);
    } catch (err) {
      toast.error('Código inválido', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  }

  function goBack() {
    setStepTwo(true);
    setStepThree(false);
  }

  function callCRC() {
    window.open('https://wa.me/558440002002');
  }

  useEffect(() => {
    if (totalTimeSeconds === 0) {
      return;
    } else {
      setTimeout(() => {
        setTotalTimeSeconds(totalTimeSeconds - 1);
      }, 1000);
    }
  }, [totalTimeSeconds]);

  // ----------------------------------------------------------------------

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} textTransform={'uppercase'}>
              Etapa 3 de 4
            </Typography>
            <Typography variant="body1" align="left" sx={{ mt: 3 }} fontWeight="bold">
              Nós enviamos uma mensagem com um código de confirmação de 6
              dígitos, por favor insira o código abaixo para validarmos seu acesso.
            </Typography>
          </Box>
        </Stack>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0',
          }}
        >
          <ReactCodeInput
            type="number"
            fields={6}
            name={'code'}
            inputMode={'numeric'}
            onChange={(e) => setPinCode(e)}
          />
        </div>
        <LoadingButton
          size="small"
          onClick={() => resendMessage()}
          disabled={totalTimeSeconds === 0 ? false : true}
        >
          Reenviar código
          {totalTimeSeconds !== 0 ? (
            <div style={{ marginLeft: '20px' }}>
              <span>{minutes.toString().padStart(2, '0')}</span>:
              <span>{seconds.toString().padStart(2, '0')}</span>
            </div>
          ) : (
            ''
          )}
        </LoadingButton>
        <Typography variant="body2" align="left" sx={{ mt: 10, mb: 2 }} fontWeight="500" marginBottom="5px">
          Está com dificuldades para receber o código?
        </Typography>
        <Button fullWidth size="large" variant="outlined" onClick={callCRC}>
          Fale conosco
        </Button>
        <BoxButtonUpdate>
          <LoadingButton
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            style={{ marginRight: 10 }}
            onClick={() => goBack()}
          >
            <MdOutlineKeyboardArrowLeft />
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            onClick={() => acessStepThree()}
          >
            Avançar
            <MdOutlineKeyboardArrowRight />
          </LoadingButton>
        </BoxButtonUpdate>
      </ContentStyle>
    </Container>
  );
}
