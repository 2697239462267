import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface FormUpdateContextProps {
  children: ReactNode;
}

interface FormUpdateProviderProps {
  stepOne: boolean;
  setStepOne: Dispatch<SetStateAction<boolean>>;
  stepTwo: boolean;
  setStepTwo: Dispatch<SetStateAction<boolean>>;
  stepThree: boolean;
  setStepThree: Dispatch<SetStateAction<boolean>>;
  stepFour: boolean;
  setStepFour: Dispatch<SetStateAction<boolean>>;
  resetPasswordEmail: boolean;
  setResetPasswordEmail: Dispatch<SetStateAction<boolean>>;
  resetPasswordPhone: boolean;
  setResetPasswordPhone: Dispatch<SetStateAction<boolean>>;
  typeSend: 'whatsapp' | 'sms' | 'email';
  setTypeSend: Dispatch<SetStateAction<'whatsapp' | 'sms' | 'email'>>;
  currentUser: User;
  handleCurrentUser: (user: User) => void;
}

interface User {
  id: string;
  name: string;
  cpf: string;
  email: string;
  phone_number: string;
  zip_code: string;
  adress: string;
  house_number: string;
  neighborhood: string;
  city: string;
  complement: string;
  reference_point: string;
  marital_status: string;
  state: string;
  use_terms: boolean;
  newsletter: boolean;
}

export const FormUpdateContext = createContext({} as FormUpdateProviderProps);

export const FormUpdateProvider = ({ children }: FormUpdateContextProps) => {
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(false);
  const [resetPasswordPhone, setResetPasswordPhone] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: '',
    name: '',
    cpf: '',
    email: '',
    phone_number: '',
    zip_code: '',
    adress: '',
    house_number: '',
    neighborhood: '',
    complement: '',
    reference_point: '',
    marital_status: '',
    city: '',
    state: '',
    use_terms: true,
    newsletter: true,
  });

  const [typeSend, setTypeSend] = useState<'whatsapp' | 'sms' | 'email'>('sms');

  function handleCurrentUser(user: User) {
    setCurrentUser(user);
  }

  return (
    <FormUpdateContext.Provider
      value={{
        stepOne,
        setStepOne,
        stepTwo,
        setStepTwo,
        stepThree,
        setStepThree,
        stepFour,
        setStepFour,
        resetPasswordEmail,
        setResetPasswordEmail,
        resetPasswordPhone,
        setResetPasswordPhone,
        typeSend,
        setTypeSend,
        currentUser,
        handleCurrentUser,
      }}
    >
      {children}
    </FormUpdateContext.Provider>
  );
};

export const useUpdateSteps = () => useContext(FormUpdateContext);
