import * as Yup from 'yup';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, CircularProgress, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import axiosInstance from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { Steps } from '..';
import { useSearchParams } from 'react-router-dom';
import { useDependentAccess } from 'src/contexts/FirstAccessDependentContext';

// ----------------------------------------------------------------------

type FormValuesProps = {
  birthdate: Date;
  afterSubmit?: string;
};

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'right',
  marginTop: '16px',
  // position: 'fixed',
  // bottom: '24px',
  // right: '30px',
}));

const ButtonSubmit = styled('button')(({ theme }) => ({
  border: 'none',
  width: '138px',
  height: '48px',
  backgroundColor: '#184981',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  fontWeight: 700,
  cursor: 'pointer',
}));

interface CheckDependentFormProps {
  changeStep: (step: Steps) => void;
}

export function CheckDependentForm({ changeStep }: CheckDependentFormProps) {
  const isMountedRef = useIsMountedRef();
  const [searchParams] = useSearchParams();

  const { handleDependent } = useDependentAccess();

  const CheckDependentSchema = Yup.object().shape({
    birthdate: Yup.date()
      .typeError('Digite uma data válida')
      .required('Digite a data de nascimento'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CheckDependentSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  function formatDate(date: Date) {
    const dateFormatted = date.toISOString();
    const datePart = dateFormatted.split('T');
    return datePart[0];
  }

  const onSubmit = async (data: FormValuesProps) => {
    const link = searchParams.get('link');

    try {
      const response = await axiosInstance.post(`/users/dependent/${link}`, {
        birthdate: formatDate(data.birthdate),
      });

      const token = response.headers['x-auth-token'];

      handleDependent(response.data, token);
      changeStep('updateDependentStep');
    } catch (error) {
      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Box>
          <Typography color="primary" variant="button">
            Data de nascimento
          </Typography>
          <RHFTextField name="birthdate" type="date" />
        </Box>
      </Stack>
      <BoxButtonUpdate>
        <LoadingButton size="large" sx={{ marginRight: '16px' }}>
          <MdOutlineKeyboardArrowLeft size={18} />
          Voltar
        </LoadingButton>
        <ButtonSubmit type="submit">
          {isSubmitting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            <>
              <span>Avançar</span>

              <MdOutlineKeyboardArrowRight size={18} />
            </>
          )}
        </ButtonSubmit>
      </BoxButtonUpdate>
    </FormProvider>
  );
}
