import { createContext, ReactNode, useContext, useState } from 'react';

interface DependentAccessContextProps {
  children: ReactNode;
}

export type Steps = 'checkDependentStep' | 'updateDependentStep';

interface DependentAccessProviderProps {
  dependent: Dependent;
  accessToken: string;
  handleDependent: (dependent: Dependent, token: string) => void;

  currentStep: Steps;
  handleStep: (step: Steps) => void;
}

interface Dependent {
  id: string;
  name: string;
  cpf: string;
  email: string;
  phone_number: string;
  use_terms: boolean;
  newsletter: boolean;
}

export const DependentAccessContext = createContext({} as DependentAccessProviderProps);

export const DependentAccessProvider = ({ children }: DependentAccessContextProps) => {
  const [accessToken, setAccessToken] = useState('');
  const [currentStep, setCurrentStep] = useState<Steps>('checkDependentStep');
  const [dependent, setDependent] = useState({
    id: '',
    name: '',
    cpf: '',
    email: '',
    phone_number: '',
    use_terms: true,
    newsletter: true,
  });

  function handleDependent(dependent: Dependent, token: string) {
    setDependent(dependent);
    setAccessToken(token);
  }

  function handleStep(step: Steps) {
    setCurrentStep(step);
  }

  return (
    <DependentAccessContext.Provider
      value={{
        dependent,
        accessToken,
        handleDependent,
        currentStep,
        handleStep,
      }}
    >
      {children}
    </DependentAccessContext.Provider>
  );
};

export const useDependentAccess = () => useContext(DependentAccessContext);

