import { Card, Typography, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { isMobileSafari, isOpera, isSafari } from 'react-device-detect';
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';
import axiosInstance from 'src/utils/axios';

interface CardNewsProps {
  id?: string;
  image: string;
  title: string;
  link: string;
  width?: string;
  height?: string;
}

export default function CardBanners({ image, title, link, id, width, height }: CardNewsProps) {
  const [loadingLecupon, setLoadingLecupon] = useState(false);

  const { user } = useAuth();

  async function handleClickCard() {
    registerClick();

    if (link === 'https://sempremaisbeneficios.planosempre.com.br') {
      lecupon();
    } else {
      setLoadingLecupon(true);

      // Caso seja um link do Sempre+Beneficios
      if (link.startsWith('https://sempremaisbeneficios.planosempre.com.br')) {
        const redirectTo = link.replace('https://sempremaisbeneficios.planosempre.com.br', '');
        const smartLink = await getSmartLink();
        if (smartLink) {
          link = smartLink + `&redirect_to=${redirectTo}`;
        }
      }

      if (isSafari || isMobileSafari || isOpera) {
        window.location.assign(link);
      } else {
        window.open(link, '_blank');
      }
    }

    setLoadingLecupon(false);
  }

  async function getSmartLink(): Promise<string | undefined> {
    try {
      const response = await axiosInstance.get(`/users/lecupon/getlecupon/${user.cpf}`);
      return response.data.web_smart_link;
    } catch (error) {
      console.error(error);
      toast.error(`Falha ao acessar o Sempre+Benefícios.`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      return;
    }
  }

  // Registra o click no banner
  async function registerClick() {
    return axiosInstance.get(`/users/banners/click/${id}`);
  }

  async function lecupon() {
    setLoadingLecupon(true);
    try {
      const smartLink = await getSmartLink();
      if (!smartLink) throw new Error('Falha ao acessar o Sempre+Benefícios.');

      if (isSafari || isMobileSafari || isOpera) {
        window.location.assign(smartLink);
      } else {
        window.open(smartLink, '_blank');
      }

      setLoadingLecupon(false);
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingLecupon(false);
    }
  }

  return (
    <Card
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: width ? width : '360px',
        height: height ? height : '230px',
        margin: '10px',
        position: 'relative',
      }}
      onClick={() => handleClickCard()}
    >
      {!loadingLecupon ? (
        <div
          style={{
            position: 'absolute',
            top: '120px',
            left: '7px',
            display: 'none',
          }}
        >
          <Typography variant={'subtitle2'} fontWeight={600} fontSize="13px" color="white">
            {title}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            opacity: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          <CircularProgress />
        </div>
      )}
    </Card>
  );
}

