import * as Yup from 'yup';
import { useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, IconButton, InputAdornment, Button, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { FormProvider, RHFTextField, RHFCheckbox } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import styled from '@emotion/styled';
import axiosInstance from 'src/utils/axios';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Steps } from '..';
import InputMask from 'react-input-mask';
import { useDependentAccess } from 'src/contexts/FirstAccessDependentContext';
import { regexCamp } from 'src/sections/auth/updateAccount/UpdateInitialForm';
import { PasswordRequirement } from 'src/sections/auth/reset-password/PasswordRequirement';
// ----------------------------------------------------------------------

type FormValuesProps = {
  cpf: string;
  name: string;
  email: string;
  contact: string;
  // zipCode: string;
  // address: string;
  // house_number: string;
  // city: string;
  // state: string;
  // maritalStatus: string;
  password: string;
  confirmPassword: string;
  afterSubmit?: string;
};

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

interface UpdateDependentFormProps {
  changeStep: (step: Steps) => void;
  handleOpenPrivateModal: () => void;
  handleOpenPrivacyModal: () => void;
}

export function UpdateDependentForm({
  changeStep,
  handleOpenPrivateModal,
  handleOpenPrivacyModal,
}: UpdateDependentFormProps) {
  const { login } = useAuth();
  const [loadSubmit, setLoadSubmit] = useState(false);

  const { dependent, accessToken } = useDependentAccess();

  const [showPassword, setShowPassword] = useState(false);

  const isMountedRef = useIsMountedRef();

  const regexPassword =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm;

  const LoginSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('Digite o CPF')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    name: Yup.string().required('Digite o nome completo').nullable(),
    email: Yup.string()
      .email('digite um E-mail valido')
      .required('Digite o e-mail')
      .nullable()
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
    contact: Yup.string().required('Digite o numero de telefone').nullable(),
    // zipCode: Yup.string().required('Digite o Cep'),
    // address: Yup.string().required('Digite o Endereço'),
    // house_number: Yup.string().required(),
    // city: Yup.string().required(),
    // state: Yup.string().required(),
    // maritalStatus: Yup.string().required(),
    password: Yup.string()
      .required('Digite uma senha')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),
    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
    agreeTerms: Yup.boolean()
      .oneOf([true], 'Você deve aceitar os Termos e Condições.')
      .required('Você deve aceitar os Termos e Condições.'),
    newsletter: Yup.boolean(),
  });
  const defaultValues = {
    cpf: dependent.cpf,
    name: dependent.name,
    email: dependent.email,
    contact: dependent.phone_number,
    // zipCode: dependent.zip_code,
    // address: dependent.adress,
    // house_number: dependent.house_number,
    // city: dependent.city,
    // state: dependent.state,
    // maritalStatus: 'Solteiro(a)',
    agreeTerms: dependent.use_terms,
    newsletter: dependent.newsletter,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    setLoadSubmit(true);
    try {
      await axiosInstance.patch(`/users/update-dependent/${dependent.id}`, {
        cpf: data.cpf.replace(/[^0-9]/g, ''),
        name: data.name,
        phone_number: data.contact.replace(/[^0-9]/g, ''),
        email: data.email,
        password: data.password,
        updated_at: new Date(),
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      );

      await login(data.cpf.replace(/[^0-9]/g, ''), data.password);
      window.location.href = PATH_DASHBOARD.general.app;
    } catch (error) {
      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    } finally {
      setLoadSubmit(false);
    }
  };

  function goBack() {
    changeStep('checkDependentStep');
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Controller
          name="cpf"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputMask
              mask="999.999.999-99"
              value={value}
              onChange={onChange}
              disabled={dependent.cpf ? true : false}
            >
              <TextField
                name="cpf"
                type="text"
                label="CPF"
                fullWidth
                error={Boolean(errors.cpf)}
                helperText={error?.message}
                disabled={dependent.cpf ? true : false}
              />
            </InputMask>
          )}
        />

        <RHFTextField name="name" label="Nome Completo" disabled />
        <RHFTextField name="email" type="email" label="Email" />
        <Controller
          name="contact"
          control={methods.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputMask mask="(99)9 9999-9999" value={value} onChange={onChange}>
              <TextField
                name="contact"
                label="Contato"
                type="text"
                fullWidth
                error={Boolean(errors.contact)}
                helperText={error?.message}
              />
            </InputMask>
          )}
        />
        {/* <RHFTextField name="zipCode" label="Cep" />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '60%' }}>
            <RHFTextField name="address" label="Endereço" />
          </div>
          <div style={{ width: '30%' }}>
            <RHFTextField name="house_number" type="text" label="Número" />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '50%' }}>
            <RHFTextField name="city" type="text" label="Cidade" />
          </div>
          <div style={{ width: '40%' }}>
            <RHFTextField name="state" type="text" label="estado" />
          </div>
        </div>
        <RHFSelect name="maritalStatus" label="Estado Civil">
          <option value="0" disabled>
            Selecione
          </option>
          {maritalStatusList.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </RHFSelect> */}
        <RHFTextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirmar Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordRequirement />
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Button onClick={handleOpenPrivateModal}>Termos de Uso</Button> e{' '}
          <Button onClick={handleOpenPrivacyModal}>Políticas de Privacidade.</Button>
        </Box>
        <RHFCheckbox
          name="agreeTerms"
          label="Confirmo que li e estou de acordo com os Termos de Uso e Políticas de Privacidade."
        />
        <RHFCheckbox
          name="newsletter"
          label="Desejo assinar a Newsletter e receber notícias e ofertas através de e-mail, SMS e Whastapp."
        />
      </Stack>
      <BoxButtonUpdate>
        <LoadingButton fullWidth size="large" onClick={() => goBack()}>
          <MdOutlineKeyboardArrowLeft />
          Voltar
        </LoadingButton>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loadSubmit}
        >
          Finalizar
          <MdOutlineKeyboardArrowRight />
        </LoadingButton>
      </BoxButtonUpdate>
    </FormProvider>
  );
}

