import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Button } from '@mui/material';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import { useSnackbar } from 'notistack';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

const TextVerify = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginBottom: '20px',
}));

// ----------------------------------------------------------------------

export default function StepTwo() {
  const [sendMethod, setSendMethod] = useState<'sms' | 'email'>('sms');

  const { setStepOne, setStepTwo, setStepThree, typeSend, setTypeSend, currentUser } =
    useUpdateSteps();
  const { enqueueSnackbar } = useSnackbar();

  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    const isValidEmail = currentUser.email.includes('@') && currentUser.email.includes('.');
    setIsValidEmail(isValidEmail);
  }, [currentUser]);

  async function accessStepTwo(type: 'whatsapp' | 'sms' | 'email') {
    setTypeSend(type);

    try {
      if (type === 'sms' || type === 'whatsapp') {
        const response = await axiosInstance.post(
          `/users/confirmation/${currentUser.phone_number}?type=${type}`,
          {
            id: currentUser.id,
          }
        );

        const { code } = response.data;

        if (process.env.REACT_APP_ENABLE_TEST === 'true') {
          enqueueSnackbar(`codigo ${code}`, {
            persist: true,
          });
        }
      } else if (type === 'email') {
        const response = await axiosInstance.post(
          `/users/confirmation/mail`,
          {
            email: currentUser.email,
            cpf: currentUser.cpf,
          }
        );

        const { code } = response.data;

        if (process.env.REACT_APP_ENABLE_TEST === 'true') {
          enqueueSnackbar(`codigo ${code}`, {
            persist: true,
          });
        }
      } else {
        return;
      }

      setStepTwo(false);
      setStepThree(true);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  }

  function goBack() {
    setStepOne(true);
    setStepTwo(false);
  }

  function callCRC() {
    window.open('https://wa.me/558440002002');
  }

  function ButtonsMethods() {
    return (
      <Box marginTop={5}>
        <Typography align="left">
          Escolha como você quer receber o código de verificação:
        </Typography>
        <Stack direction="row" alignItems="start" gap={2} marginTop={2}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant={sendMethod === 'sms' ? "contained" : "outlined"}
            onClick={() => {
              setSendMethod('sms');
            }}
          >
            SMS
          </Button>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant={sendMethod === 'email' ? "contained" : "outlined"}
            onClick={() => {
              setSendMethod('email');
            }}
          >
            E-mail
          </Button>
        </Stack>
      </Box>
    );
  }

  function ConfirmSMS() {
    return (
      <>
        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 2 }}
          fontWeight="bold"
          marginBottom="5px"
        >
          Confirme se o número de celular abaixo é o seu contato.
        </Typography>
        <TextVerify>{'(**) *****-' + currentUser.phone_number.slice(7)}</TextVerify>
        {/* <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => accessStepTwo('whatsapp')}
        >
          Receber código pelo WhatsApp
        </Button> */}
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => accessStepTwo('sms')}
          sx={{ mt: '1rem' }}
        >
          Receber código por SMS
        </Button>
      </>
    );
  }

  function ConfirmEmail() {
    const email = currentUser.email.split('@');
    const obscureEmail = email[0].slice(0, 2) + '***' + email[0].slice(-1) + '@**.***';


    if (!isValidEmail) {
      return (
        <>
          <Typography
            variant="body1"
            align="center"
            sx={{ mt: 10 }}
            fontWeight="bold"
          >
            O seu e-mail cadastrado é inválido. Por favor, entre em contato com a empresa.
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 2 }}
          fontWeight="bold"
          marginBottom="5px"
        >
          Confirme se o e-mail abaixo é o seu contato.
        </Typography>
        <TextVerify>{obscureEmail}</TextVerify>
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => accessStepTwo('email')}
          sx={{ mt: '1rem' }}
        >
          Receber código por E-mail
        </Button>
      </>
    );
  }

  // ----------------------------------------------------------------------

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} textTransform={'uppercase'}>
              Etapa 2 de 4
            </Typography>
            <ButtonsMethods />
          </Box>
        </Stack>
        {sendMethod === 'sms' ? <ConfirmSMS /> : <ConfirmEmail />}
        <Typography variant="body2" align="left" sx={{ mt: 10, mb: 2 }} fontWeight="500" marginBottom="5px" visibility={(sendMethod === 'email' && !isValidEmail) ? 'hidden' : 'visible'}>
          Não possui mais este {sendMethod === 'sms' ? 'número' : 'e-mail'} ou não consegue receber o código de confirmação?
        </Typography>
        <Button fullWidth size="large" variant="outlined" onClick={() => callCRC()}>
          Fale conosco
        </Button>
        <LoadingButton
          fullWidth
          variant="text"
          color="secondary"
          size="large"
          style={{ marginTop: 20 }}
          onClick={() => goBack()}
        >
          <MdOutlineKeyboardArrowLeft />
          Voltar
        </LoadingButton>
        {/* <BoxButtonUpdate>
          <LoadingButton
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            style={{ marginRight: 10 }}
            onClick={() => goBack()}
          >
            <MdOutlineKeyboardArrowLeft />
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!currentUser.phone_number}
            onClick={() => accessStepTwo(typeSend)}
          >
            Avançar
            <MdOutlineKeyboardArrowRight />
          </LoadingButton>
        </BoxButtonUpdate> */}
      </ContentStyle>
    </Container>
  );
}