import Dialog from '@mui/material/Dialog';

import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogContent, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IoCloseOutline } from 'react-icons/io5';

import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axiosInstance from 'src/utils/axios';

import { toast } from 'react-toastify';

import axios from 'axios';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { statesBR } from 'src/@types/statesBR';
import { IDependent } from 'src/pages/dashboard/@types';
import { getCityProps } from 'src/sections/auth/updateAccount/UpdateForm';
import { regexCamp } from 'src/sections/auth/updateAccount/UpdateInitialForm';
import Iconify from '../Iconify';

type FormValuesProps = {
  name: string;
  contractNumber: string;
  cpf: string;
  maritalStatus: string;
  email: string;
  confirmEmail: string;
  phoneNumber: string;
  homePhone: string;
  otherPhone: string;
  zipCode: string;
  adress: string;
  houseNumber: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  referencePoint: string;
  afterSubmit?: string;
};

export interface FormDialogProps {
  open: boolean;
  dependent: any;
  onClose: () => void;
  editDependent: (dependent: IDependent) => void;
}

let listCity: getCityProps[] = [];

const Header = styled('div')(({ theme }) => ({}));

export function FormDialog(props: FormDialogProps) {
  const { onClose, open, dependent, editDependent } = props;

  const handleClose = () => {
    onClose();
    setValue('name', dependent.name);

    setValue('email', dependent.email);
  };

  const isMountedRef = useIsMountedRef();

  //const theme = useTheme();

  const [listCityByState, setListCityByState] = useState<getCityProps[]>([]);

  const UpdateDependentSchema = Yup.object().shape({
    name: Yup.string().required('Digite o nome completo').nullable(),
    cpf: Yup.string()
      .required('O CPF é obrigatório')
      .nullable()
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      ),
    maritalStatus: Yup.string().required('O Estado Civil é obrigatório').nullable(),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('Digite o e-mail')
      .nullable()
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
    confirmEmail: Yup.string()
      .required('Digite o e-mail novamente!')
      .oneOf([Yup.ref('email')], 'Os e-mails devem ser iguais!'),
    phoneNumber: Yup.string()
      .required('Digite o número do celular')
      .test(
        'Digite o número do celular corretamente!',
        'Digite o número do celular corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    homePhone: Yup.string()
      .test(
        'Digite o número do telefone corretamente!',
        'Digite o número do telefone corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    otherPhone: Yup.string()
      .test(
        'Digite o número do telefone corretamente!',
        'Digite o número do telefone corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    zipCode: Yup.string()
      .required('Digite o CEP')
      .test(
        'Digite o CEP corretamente!',
        'Digite o CEP corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    adress: Yup.string()
      .required('Digite o Logradouro')
      .nullable()
      .max(40, 'O Logradouro deve ter no máximo 40 caracteres'),
    houseNumber: Yup.string()
      .required('Digite o Número')
      .nullable()
      .max(15, 'O Número da residência deve ter no máximo 15 caracteres'),
    neighborhood: Yup.string().required('Digite o Bairro').nullable(),
    city: Yup.string().required('Digite a Cidade').nullable(),
    state: Yup.string().required('O Estado é obrigatório').nullable(),
    complement: Yup.string().nullable().max(50, 'O complemento deve ter no máximo 50 caracteres'),
    referencePoint: Yup.string()
      .required('Digite o Ponto de Referência')
      .nullable()
      .max(70, 'O Ponto de referência deve ter no máximo 70 caracteres'),
  });

  const defaultValues = {
    name: dependent.name,
    contractNumber: dependent.contract_number,
    cpf: dependent.cpf,
    maritalStatus: dependent.marital_status,
    email: dependent.email,
    confirmEmail: dependent.email,
    phoneNumber: dependent.phone_number,
    homePhone: dependent.home_phone,
    otherPhone: dependent.other_phone,
    zipCode: dependent.zip_code,
    adress: dependent.adress,
    houseNumber: dependent.house_number,
    neighborhood: dependent.neighborhood,
    city: dependent.city,
    state: dependent.state,
    complement: dependent.complement,
    referencePoint: dependent.reference_point,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateDependentSchema),
    defaultValues,
  });

  async function getCity(value: string) {
    setListCityByState([]);
    listCity = [];
    setValue('state', value);
    let { data } = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    );

    // eslint-disable-next-line array-callback-return
    await data.map((i: any) => {
      let resCity = {
        id: i.id,
        city: i.nome,
      };

      listCity.push(resCity);
    });

    setListCityByState(listCity);
  }

  async function getLocationForCep() {
    const cep = getValues('zipCode');
    if (!cep) return;

    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^\d]+/g, '')}/json/`);

      if (data.erro === true) {
        toast.error('Erro ao buscar o CEP, preencha as informações manualmente!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }

      const { localidade, logradouro, uf, bairro } = data;

      setValue('adress', logradouro);
      setValue('neighborhood', bairro);
      setValue('city', localidade);
      setValue('state', uf);

      getCity(getValues('state'));

      // Atualiza os erros do formulário
      trigger(['adress', 'neighborhood', 'city', 'state']);

    } catch (err) {
      toast.error('Não foi possivel pegar os dados pelo cep!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      setValue('adress', '');
      setValue('neighborhood', ' ');
      setValue('city', ' ');
      setValue('state', ' ');
    }
  }

  async function getLocationForCepInitial() {
    const cep = getValues('zipCode');
    if (!cep) return;
    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^\d]+/g, '')}/json/`);
      const { localidade, uf } = data;
      setValue('city', localidade);
      setValue('state', uf);
      getCity(getValues('state'));
    } catch (err) {
      setValue('state', dependent.state);
      setValue('city', dependent.city);
    }
  }

  async function renderCity() {
    getCity(getValues('state'));
  }

  const {
    setValue,
    getValues,
    reset,
    setError,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const { data: newDependent } = await axiosInstance.patch(
        `/users/update-dependent/${dependent.id}`,
        {
          name: data.name,
          user_id: dependent.user_id,
          phone_number: data.phoneNumber?.replace(/[^\d]+/g, ''),
          home_phone: data.homePhone?.replace(/[^\d]+/g, ''),
          other_phone: data.otherPhone?.replace(/[^\d]+/g, ''),
          cpf: data.cpf?.replace(/[^\d]+/g, ''),
          marital_status: data.maritalStatus,
          email: data.email,
          zip_code: data.zipCode,
          adress: data.adress,
          house_number: data.houseNumber,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          complement: data.complement,
          reference_point: data.referencePoint,
        }
      );

      toast.success('Dependente atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      editDependent(newDependent);
      // window.location.href = PATH_DASHBOARD.user.dependents;
      onClose();
    } catch (error) {
      console.log(error);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  useEffect(() => {
    getLocationForCepInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog onClose={() => null} open={open} fullWidth maxWidth="md">
      <DialogContent sx={{ padding: '0px' }}>
        <Header
          sx={{
            padding: '20px 20px 24px 20px',
            borderBottom: '1px solid  rgba(145, 158, 171, 0.24)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Editar dependente</Typography>

          <IoCloseOutline size={24} onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Header>
        <Box sx={{ width: '100%', marginTop: '8px', padding: '20px' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}

              <Typography variant="h6" gutterBottom>
                Dados Pessoais
              </Typography>

              <RHFTextField
                size="medium"
                name="contractNumber"
                label="Contrato Vinculado"
                disabled
              />
              <RHFTextField size="medium" name="name" label="Nome Completo *" disabled />
              <Controller
                name="cpf"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask
                    mask="999.999.999-99"
                    value={value}
                    disabled={dependent.cpf ? true : false}
                    onChange={onChange}
                  >
                    <TextField
                      name="cpf"
                      type="text"
                      label="CPF *"
                      fullWidth
                      disabled={dependent.cpf ? true : false}
                      error={Boolean(errors.cpf)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <RHFSelect
                name="maritalStatus"
                label="Estado Civil *"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <option value="" disabled selected>
                  Selecione o Estado Civil
                </option>
                <option value="Solteiro(a)">Solteiro(a)</option>
                <option value="Casado(a)">Casado(a)</option>
                <option value="Divorciado(a)">Divorciado(a)</option>
                <option value="Viúvo(a)">Viúvo(a)</option>
                <option value="Desquitado(a)">Desquitado(a)</option>
                <option value="União Estável">União Estável</option>
                <option value="Outros">Outros</option>
              </RHFSelect>

              <RHFTextField size="medium" name="email" type="email" label="E-mail *" />
              <RHFTextField size="medium" name="confirmEmail" label="Confirmar E-mail *" />
              <Controller
                name="phoneNumber"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="phoneNumber"
                      label="Número do Celular *"
                      type="text"
                      fullWidth
                      error={Boolean(errors.phoneNumber)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <Controller
                name="homePhone"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="homePhone"
                      label="Telefone Residencial"
                      type="text"
                      fullWidth
                      error={Boolean(errors.homePhone)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <Controller
                name="otherPhone"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="otherPhone"
                      label="Outro Telefone"
                      type="text"
                      fullWidth
                      error={Boolean(errors.otherPhone)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />

              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>

              <div style={{ display: 'flex', position: 'relative' }}>
                <Controller
                  name="zipCode"
                  control={methods.control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <InputMask mask="99999-999" value={value} onChange={onChange}>
                      <TextField
                        sx={{ flex: 1 }}
                        name="zipCode"
                        type="text"
                        fullWidth
                        label="CEP *"
                        error={Boolean(errors.zipCode)}
                        helperText={error?.message}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            getLocationForCep();
                          }
                        }}
                      />
                    </InputMask>
                  )}
                />
                <Button
                  style={{ position: 'absolute', right: '0', height: '100%' }}
                  onClick={() => getLocationForCep()}
                >
                  <Iconify icon="ic:baseline-search" fontSize={'24px'} />
                </Button>
              </div>
              <RHFTextField size="medium" name="adress" label="Logradouro *" />
              <RHFTextField size="medium" name="houseNumber" label="Número *" />
              <RHFTextField size="medium" name="neighborhood" label="Bairro *" />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: '1rem', width: '100%' }}>
                  <Box sx={{ width: '30%' }}>
                    <RHFSelect
                      defaultValue="0"
                      name="state"
                      label="Estado *"
                      onChange={(e) => getCity(e.target.value)}
                      onClick={() => renderCity()}
                    >
                      <option value="0" selected>
                        Selecione
                      </option>
                      {statesBR.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </RHFSelect>
                  </Box>
                  <Box sx={{ width: '70%' }}>
                    <RHFSelect
                      defaultValue="0"
                      name="city"
                      label="Cidade *"
                      disabled={listCityByState.length === 0 && true}
                    >
                      <option value="0" selected hidden>
                        Selecione
                      </option>
                      {listCityByState.map((i) => (
                        <option key={i.id} value={i.city}>
                          {i.city}
                        </option>
                      ))}
                    </RHFSelect>
                  </Box>
                </Box>
              </div>
              <RHFTextField size="medium" name="complement" label="Complemento" />
              <RHFTextField size="medium" name="referencePoint" label="Ponto de Referência *" />

              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Salvar alteração
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
