import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { MotionViewport, varFade } from 'src/components/animate';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import useResponsive from 'src/hooks/useResponsive';
import axiosInstance from 'src/utils/axios';
import CardBanners from './CardBanners';

interface IListNews {
  id: string;
  image: string;
  title: string;
  description: string;
  link: string;
  active: boolean;
  tags: string[];
  order: number;
  created_at: string;
  updated_at: string;
  presignedUrl: string;
}

async function callCrc() {
  try {
    const a = document.createElement('a');
    a.href = 'tel:8440002002';
    a.click();
  } catch (err) {
    console.log('Não foi possível chamar o CRC');
  }
}

export default function CarrouselBanners() {
  const { selectedContract } = useAuth();
  const carouselRef = useRef<Slider>(null);
  const [listNews, setListNews] = useState<IListNews[]>([]);

  const [removeLoading, setRemoveLoading] = useState(false);

  const settings = {
    arrows: true,
    slidesToShow: Math.min(3, listNews.length),
    infinite: listNews.length > 3,
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1475,
        settings: { slidesToShow: 2.5 },
      },
      {
        breakpoint: 1350,
        settings: { slidesToShow: 2.05 },
      },
      {
        breakpoint: 1199,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1150,
        settings: { slidesToShow: 2.5 },
      },
      {
        breakpoint: 1045,
        settings: { slidesToShow: 2.3 },
      },
      {
        breakpoint: 890,
        settings: { slidesToShow: 2.05 },
      },
      {
        breakpoint: 790,
        settings: { slidesToShow: 1.6 },
      },
      {
        breakpoint: 685,
        settings: { slidesToShow: 1.2 },
      },
      {
        breakpoint: 460,
        settings: { slidesToShow: 1.45 },
      },
      {
        breakpoint: 390,
        settings: { slidesToShow: 1.3 },
      },
      {
        breakpoint: 325,
        settings: { slidesToShow: 1.15 },
      },
    ],
  };

  const getBanners = async () => {
    const tmp: IListNews[] = [];
    var { data } = await axiosInstance.get('/users/banners/list');

    data.filter((list: IListNews) => list.active).map((i: IListNews) => tmp.push(i));
    setListNews(tmp);
    setRemoveLoading(true);
  };

  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return listNews.length > 0 ? (
    <>
      <Divider sx={{ margin: '25px 0px' }} />
      <Typography
        variant="h6"
        fontWeight={600}
        fontSize="18px"
        color="black"
        textAlign="left"
        marginBottom="25px"
      >
        Descubra mais
      </Typography>
      {removeLoading ? (
        <Container component={MotionViewport} sx={{ padding: '0 !important' }}>
          <Slider ref={carouselRef} {...settings}>
            {listNews.length > 1 &&
              listNews.map((i: IListNews) => (
                <a href={`#${i.title}`} key={i.id}>
                  <Box
                    key={i.id}
                    sx={{ width: 'auto !important' }}
                    component={m.div}
                    variants={varFade().in}
                    ml={4}
                  >
                    <CardBanners
                      id={i.id}
                      title={i.title}
                      image={i.presignedUrl}
                      link={i.link}
                      width={isMobile ? '240px' : ''}
                      height={isMobile ? '180px' : ''}
                    />
                  </Box>
                </a>
              ))}
          </Slider>
          {listNews.length === 1 && (
            <a href={`#${listNews[0].title}`}>
              <Box
                sx={{ width: 'auto !important' }}
                component={m.div}
                variants={varFade().in}
                display="flex"
                justifyContent="center"
              >
                <CardBanners
                  id={listNews[0].id}
                  title={listNews[0].title}
                  image={listNews[0].presignedUrl}
                  link={listNews[0].link}
                  width={isMobile ? '240px' : ''}
                  height={isMobile ? '180px' : ''}
                />
              </Box>
            </a>
          )}
        </Container>
      ) : (
        <LoadingScreen />
      )}
      {selectedContract?.role === 'LEAD' && (
        <Button variant="contained" color="secondary" fullWidth onClick={callCrc}>
          Torne-se um cliente
        </Button>
      )}
    </>
  ) : (
    <></>
  );
}
